export var GENERAL_SETTINGS_MODULE = {
  type: 'module',
  code: 'MODULE_GENERAL_CONFIG',
  label: 'Config. General',
  icon: 'outline/Administrate',
  arrowDefault: true,
  tooltip: {
    title: 'Configuración general',
    description: 'Personaliza los roles y permisos, apariencia y otras preferencias de la cuenta.'
  },
  children: [{
    type: 'url',
    code: 'ADMIN.ACCOUNT_ADMIN',
    label: 'Cuenta',
    icon: 'outline/Shield',
    tooltip: {
      title: 'Cuenta',
      description: 'Gestiona los datos de la cuenta, personaliza los roles y permisos administrativos.'
    },
    url: "/org/[orgSlug]/admin/account/"
  }, {
    type: 'url',
    code: 'ADMIN.LOGS_ADMIN_COMPANY',
    label: 'Logs',
    icon: 'outline/FolderAdd',
    tooltip: {
      title: 'Logs',
      description: 'Historial de acciones realizadas en la cuenta.'
    }
  },
  // Appearance
  {
    type: 'url',
    code: 'ADMIN.APPEARENCE_ADMIN',
    label: 'Apariencia',
    icon: 'outline/ColorPalette',
    tooltip: {
      title: 'Apariencia',
      description: 'Personaliza la apariencia y el estilo de la plataforma.'
    },
    url: "/org/[orgSlug]/admin/appearance/"
  },
  // Communications
  {
    type: 'url',
    code: 'ADMIN.COMMUNICATION_ADMIN',
    label: 'Comunicaciones',
    icon: 'outline/Megaphone',
    tooltip: {
      title: 'Comunicaciones',
      description: 'Personaliza las notificaciones para tus usuarios.'
    },
    url: "/org/[orgSlug]/admin/communication/"
  }]
};
export var HELP_LINK = {
  type: 'url',
  code: 'MODULE_HELP',
  label: 'Ayuda',
  icon: 'outline/QuestionMarkCircle',
  tooltip: {
    title: 'Ayuda',
    description: 'Accede a los recursos y canal de soporte.'
  },
  url: 'https://www.crehana.com/org/crehana/panel/guia-del-administrador/'
};