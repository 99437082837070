var homeUrls = ['/org/[orgSlug]/panel/inicio/', '/org/[orgSlug]/panel/'];
var learningUrls = ['/org/[orgSlug]/panel/content/courses/', '/org/[orgSlug]/panel/content/paths/', '/org/[orgSlug]/panel/content/projects/', '/org/[orgSlug]/panel/content/lives/', '/org/[orgSlug]/panel/content/documents/', '/org/[orgSlug]/panel/content/catalog/', '/org/[orgSlug]/panel/skill-xp-evolution/lanzados/', '/org/[orgSlug]/panel/gamification/progress/', '/org/[orgSlug]/panel/gamification/rewards/', '/org/[orgSlug]/panel/mi-tienda/', '/org/[orgSlug]/panel/integraciones/descubre/', '/org/[orgSlug]/panel/integraciones/mis-integraciones/', '/org/[orgSlug]/panel/integraciones/credenciales/', '/org/[orgSlug]/panel/estadisticas/generales/'];
var adminUrls = ['/org/[orgSlug]/panel/talent-suite/compania/campos-personalizados/', '/org/[orgSlug]/panel/talent-suite/usuarios/invitados/', '/org/[orgSlug]/panel/talent-suite/usuarios/por-invitar/', '/org/[orgSlug]/panel/talent-suite/account/information/', '/org/[orgSlug]/panel/administrar/automatizaciones/',
/*
 * Migrated frontend-admin-panel URLs
 */
// Company - Users
'/org/[orgSlug]/admin/company/users/invited/', '/org/[orgSlug]/admin/company/users/to-invite/', '/org/[orgSlug]/admin/company/users/deleted/',
// Company - Structure
'/org/[orgSlug]/admin/company/structure/',
// Company - Custom fields
'/org/[orgSlug]/admin/company/custom-fields/',
// Corporative documents
'/org/[orgSlug]/admin/corporate-documents/',
// Automations - Workflows
'org/[orgSlug]/admin/automations/workflows/'];
var generalConfigUrls = ['/org/[orgSlug]/admin/account/information/', '/org/[orgSlug]/admin/account/roles/', '/org/[orgSlug]/admin/appearance/desktop/', '/org/[orgSlug]/admin/appearance/apps/', '/org/[orgSlug]/admin/communication/'];
var analyticUrls = ['/org/[orgSlug]/panel/estadisticas/', '/org/[orgSlug]/panel/estadisticas/generales/', '/org/[orgSlug]/panel/reportes/', '/org/[orgSlug]/panel/reportes/publicados/'];
var getUrls = function getUrls(urls, organizationSlug) {
  return urls.map(function (url) {
    if (url.includes('[orgSlug]')) {
      return url.replace('[orgSlug]', organizationSlug);
    }
    return url;
  });
};
export var getAdminLearningUrls = function getAdminLearningUrls(organizationSlug) {
  return getUrls(learningUrls, organizationSlug);
};
export var getManagementUrls = function getManagementUrls(organizationSlug) {
  return getUrls(adminUrls, organizationSlug);
};
export var getGeneralConfigUrls = function getGeneralConfigUrls(organizationSlug) {
  return getUrls(generalConfigUrls, organizationSlug);
};
export var getAnalyticUrls = function getAnalyticUrls(organizationSlug) {
  return getUrls(analyticUrls, organizationSlug);
};
export var getHomeUrls = function getHomeUrls(organizationSlug) {
  return getUrls(homeUrls, organizationSlug);
};