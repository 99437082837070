import type { OrganizationInfo } from '@/views/LearnDashboard/hooks/getOrganizationTheme';

import B2BOrgLayout from './B2BOrgLayout';

interface ConditionalB2BLayoutProps {
  isB2B: boolean;
  organizationInfo?: OrganizationInfo;
  organizationSlug?: string;
}

const B2BCustomTheme: React.FC<
  React.PropsWithChildren<ConditionalB2BLayoutProps>
> = ({ isB2B, organizationInfo, organizationSlug }) => {
  const shouldRenderCustomB2BTheme =
    isB2B && !!organizationSlug && organizationInfo?.lookAndFeel;

  if (shouldRenderCustomB2BTheme) {
    return <B2BOrgLayout orgInfo={organizationInfo} />;
  }

  return null;
};

export default B2BCustomTheme;
