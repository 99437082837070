import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';

import AUTH_PAGES_ORG_INFO_QUERY from './grahpql/LearnDashboardOrgInfoQuery.b2b.graphql';
import type {
  LearnDashboardOrgInfoQuery,
  LearnDashboardOrgInfoQueryVariables,
} from './grahpql/types/LearnDashboardOrgInfoQuery';

interface GetOrganizationThemeProps {
  organizationSlug: string;
  client: ApolloClient<NormalizedCacheObject>;
  pageName?: string;
}

const getOrganizationTheme = async ({
  client,
  organizationSlug,
  pageName,
}: GetOrganizationThemeProps) => {
  try {
    const { data } = await client.query<
      LearnDashboardOrgInfoQuery,
      LearnDashboardOrgInfoQueryVariables
    >({
      query: AUTH_PAGES_ORG_INFO_QUERY,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      context: { clientName: 'b2b' },
      variables: {
        slug: organizationSlug,
      },
    });

    return data?.organizationInfo!;
  } catch (error) {
    const extra = {
      page: pageName,
      query: 'GetOrganizationTheme',
      organizationSlug,
    };

    console.error(error, { extra });
    Sentry.captureException(error, { extra });
  }
};

export default getOrganizationTheme;
