import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
var _excluded = ["submodules", "__typename"],
  _excluded2 = ["__typename"];
import { useFeatureFlag } from '@crehana/feature-flags/react';
import { isProduction } from "../../../../constants";
import { useDebugger } from "../../../debugger";
import { COLLABORATOR_LEARNING_MODULE } from "../data/full-suite-links";
import { arrayDifference, getAllCodes } from "../data/utils";
import { useConfiguration } from "../use-configuration";
import { useExcludedCodes } from "../use-menu-links/use-excluded-codes";
import { useGetModulesByOrganizationSlug } from "./use-get-modules-by-organization-slug";

/*
  This hooks handles the logic to get the modules using organizationSlug or companyId based on the available data
*/
export var useTalentModules = function useTalentModules(_ref) {
  var email = _ref.email,
    organizationId = _ref.organizationId,
    organizationSlug = _ref.organizationSlug,
    isKnowledgeHubEnabled = _ref.isKnowledgeHubEnabled,
    isLiveSessionFF = _ref.isLiveSessionFF,
    isLearningAccessLevelLMS = _ref.isLearningAccessLevelLMS,
    sidebarType = _ref.sidebarType,
    isLearningModuleActive = _ref.isLearningModuleActive;
  var modules = useGetModulesByOrganizationSlug({
    email: email,
    organizationSlug: organizationSlug
  });
  var _useExcludedCodes = useExcludedCodes({
      organizationId: organizationId,
      organizationSlug: organizationSlug,
      email: email,
      isKnowledgeHubEnabled: isKnowledgeHubEnabled,
      isLiveSessionFF: isLiveSessionFF,
      isLearningAccessLevelLMS: isLearningAccessLevelLMS
    }),
    learningCodesToExclude = _useExcludedCodes.codesToExclude,
    loadingCodesToExclude = _useExcludedCodes.isLoading;

  // Validations for Learning Admin Links
  var _useFeatureFlag = useFeatureFlag('MODULE_HANA_AI'),
    isHanaActive = _useFeatureFlag.flagValue;
  var _useConfiguration = useConfiguration({
      organizationSlug: organizationSlug
    }),
    canUseShop = _useConfiguration.canUseShop;
  var _useDebugger = useDebugger(),
    showHRISLinks = _useDebugger.showHRISLinks;
  var forceShowHRISLinks = showHRISLinks;
  var adminModulesData = proccessAdminModules({
    modules: modules.adminModules,
    isHanaActive: isHanaActive,
    canUseShop: canUseShop,
    forceShowHRISLinks: forceShowHRISLinks
  });

  // Collaborator modules
  var collaboratorModulesData = proccessCollaboratorModules({
    modules: modules.collaboratorModules,
    forceShowHRISLinks: forceShowHRISLinks,
    isLearningModuleActive: isLearningModuleActive,
    learningCodesToExclude: learningCodesToExclude
  });
  return {
    flatModulesData: sidebarType === 'collaborator' ? collaboratorModulesData : adminModulesData,
    loading: Boolean(modules.loading || loadingCodesToExclude)
  };
};
var proccessCollaboratorModules = function proccessCollaboratorModules(_ref2) {
  var modules = _ref2.modules,
    forceShowHRISLinks = _ref2.forceShowHRISLinks,
    isLearningModuleActive = _ref2.isLearningModuleActive,
    learningCodesToExclude = _ref2.learningCodesToExclude;
  var _flatModulesData = extractModulesData(modules);
  if (_flatModulesData['ANALYTICS.LEADER_RESULTS']) {
    _flatModulesData['MY_TEAM_MODULE'] = {
      code: 'MY_TEAM_MODULE',
      label: null,
      url: null
    };

    // And its children
    _flatModulesData['MY_TEAM_MODULE.FOLLOW_UP'] = {
      code: 'MY_TEAM_MODULE.FOLLOW_UP',
      label: null,
      url: generateSSOUrl('/employee/dashboard-leader')
    };
  }
  if (forceShowHRISLinks) {
    var HRIS_LINKS = [];
    for (var _i = 0, _HRIS_LINKS = HRIS_LINKS; _i < _HRIS_LINKS.length; _i++) {
      var link = _HRIS_LINKS[_i];
      _flatModulesData[link] = {
        code: link,
        label: null,
        url: null
      };
    }
  }

  // Process Learning modules
  if (isLearningModuleActive) {
    _flatModulesData['MODULE_LEARNING'] = {
      code: 'MODULE_LEARNING',
      label: 'Aprendizaje',
      url: null
    };
    // Calculate the allowed codes using the whole list of learning codes minus
    // the excluded codes
    var ALLOWED_LEARNING_CODES = arrayDifference(getAllCodes(COLLABORATOR_LEARNING_MODULE.children || []), learningCodesToExclude);
    var _iterator = _createForOfIteratorHelper(ALLOWED_LEARNING_CODES),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var code = _step.value;
        _flatModulesData[code] = {
          code: code,
          label: null,
          url: null
        };
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  return _flatModulesData;
};
var proccessAdminModules = function proccessAdminModules(params) {
  var modules = params.modules,
    isHanaActive = params.isHanaActive,
    canUseShop = params.canUseShop,
    forceShowHRISLinks = params.forceShowHRISLinks;
  var _flatModulesData = extractModulesData(modules);

  // Add the parent module if the children automations is available
  if (_flatModulesData['ADMIN.AUTOMATIONS_ADMIN']) {
    // Parent Automations link
    _flatModulesData['MODULE_MANAGEMENT.AUTOMATIONS'] = {
      code: 'MODULE_MANAGEMENT.AUTOMATIONS',
      label: 'Automatizaciones',
      url: null
    };
    // Automations children link - Assignation
    _flatModulesData['ADMIN.AUTOMATIONS_ADMIN'] = {
      code: 'ADMIN.AUTOMATIONS_ADMIN',
      label: 'Asignación de aprendizaje',
      url: "/org/[orgSlug]/panel/administrar/automatizaciones/"
    };
  }

  // HRIS Links
  var HRIS_LINKS = [
  // Management links
  'ADMIN.COMPANYPAR.AREAS_AND_QUARTERS', 'ADMIN.COMPANYPAR.USERS_COMPANY', 'ADMIN.COMPANYPAR.ORGCHART_COMPANY', 'MODULE_MANAGEMENT.CORPORATIVE_DOCUMENTS', 'MODULE_MANAGEMENT.TIME_OFF', 'MODULE_MANAGEMENT.TIME_OFF.POLICIES', 'MODULE_MANAGEMENT.TIME_OFF.REQUESTS',
  // 'MODULE_MANAGEMENT.TIME_OFF.HOLIDAYS',
  // 'MODULE_MANAGEMENT.TIME_OFF.CALENDAR',
  // 'MODULE_MANAGEMENT.TIME_OFF.CONFIGURATION',

  'MODULE_MANAGEMENT.AUTOMATIONS', 'MODULE_MANAGEMENT.AUTOMATIONS.WORKFLOWS'];
  if (forceShowHRISLinks) {
    var _iterator2 = _createForOfIteratorHelper(HRIS_LINKS),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var link = _step2.value;
        _flatModulesData[link] = {
          code: link,
          label: null,
          url: null
        };
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }

  // Hana AI
  if (isHanaActive) {
    _flatModulesData['HANA_AI'] = {
      code: 'HANA_AI',
      label: null,
      url: null
    };
  }

  // Shop
  if (canUseShop) {
    _flatModulesData['MODULE_LEARNING.STORE'] = {
      code: 'SHOP_MODULE',
      label: null,
      url: null
    };
    _flatModulesData['SHOP_MODULE'] = {
      code: 'MODULE_LEARNING.STORE.CONFIGURATION',
      label: null,
      url: null
    };
  }

  /**
   *
   * Default links for admin accounts
   * This section adds default modules for admin accounts
   *
   */
  // Define an array of module configurations
  var DEFAULT_LINKS = [
  // General config links
  {
    code: 'ADMIN',
    label: null,
    url: null
  }, {
    code: 'ADMIN.ACCOUNT_ADMIN',
    label: 'Cuenta',
    url: null
  }, {
    code: 'ADMIN.APPEARENCE_ADMIN',
    label: 'Logs',
    url: null
  }, {
    code: 'ADMIN.COMMUNICATION_ADMIN',
    label: 'Comunicaciones',
    url: null
  },
  // This module is always needed to render at least 1 submodule
  {
    code: 'MODULE_GENERAL_CONFIG',
    label: 'Configuración general',
    url: null
  },
  // Management user links
  {
    code: 'ADMIN.COMPANYPAR',
    label: null,
    url: null
  }, {
    code: 'ADMIN.COMPANYPAR.USERS_COMPANY',
    label: 'Usuarios',
    url: "/org/[orgSlug]/admin/company/users/"
  }, {
    code: 'ADMIN.COMPANYPAR.ORGCHART_COMPANY',
    label: 'Organigrama',
    url: generateSSOUrl('/company/employee-admin-chart')
  }, {
    code: 'ADMIN.COMPANYPAR.EXTRA_FIELDS_COMPANY',
    label: 'Atributos personalizados',
    url: "/org/[orgSlug]/admin/company/custom-fields/"
  }, {
    code: 'ADMIN.AUTOMATIONS_ADMIN',
    label: 'Asignación de aprendizaje',
    url: "/org/[orgSlug]/panel/administrar/automatizaciones/"
  },
  // Temporal hardcoded links for Learning Content
  {
    code: 'MODULE_LEARNING.CONTENT.LIVE_SESSIONS'
  }, {
    code: 'MODULE_LEARNING.CONTENT.DOCUMENTS'
  }, {
    code: 'MODULE_LEARNING.CONTENT.CATALOG'
  }];
  if (!forceShowHRISLinks) {
    DEFAULT_LINKS.push({
      code: 'ADMIN.COMPANYPAR.JOBS_COMPANY',
      label: 'Cargos y categorías',
      url: generateSSOUrl('/company/jobrole')
    });
    DEFAULT_LINKS.push({
      code: 'ADMIN.COMPANYPAR.DIVISION_COMPANY',
      label: 'Departamentos',
      url: generateSSOUrl('/company/departments')
    });
  }

  // Add modules to _flatModulesData
  DEFAULT_LINKS.forEach(function (_link) {
    _flatModulesData[_link.code] = {
      code: _link.code,
      label: _link.label,
      url: _link.url
    };
  });
  return _flatModulesData;
};
var extractModulesData = function extractModulesData(modules) {
  var moduleData = {};
  var _extractDataRecursive = function extractDataRecursive(item) {
    var parentCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var submodules = item.submodules,
      __typename = item.__typename,
      moduleWithoutSubmodulesAndTypename = _objectWithoutProperties(item, _excluded);
    var currentCode = item.code;
    if (parentCode) {
      currentCode = "".concat(parentCode, ".").concat(currentCode);
    }
    moduleData[currentCode] = _objectSpread(_objectSpread({}, moduleWithoutSubmodulesAndTypename), {}, {
      code: currentCode
    });
    if (submodules && submodules.length > 0) {
      submodules.forEach(function (submodule) {
        if ('submodules' in submodule) {
          _extractDataRecursive(submodule, currentCode);
        } else if (submodule.code) {
          var _typename = submodule.__typename,
            submoduleWithoutTypename = _objectWithoutProperties(submodule, _excluded2);
          moduleData["".concat(currentCode, ".").concat(submodule.code)] = _objectSpread(_objectSpread({}, submoduleWithoutTypename), {}, {
            code: "".concat(currentCode, ".").concat(submodule.code)
          });
        }
      });
    }
  };
  modules.forEach(function (module) {
    return _extractDataRecursive(module);
  });

  // This module is always needed to render any product
  moduleData['PRODUCTS_MODULE'] = {
    code: 'PRODUCTS_MODULE',
    url: null
  };
  moduleData['MODULE_HELP'] = {
    code: 'MODULE_HELP',
    url: null
  };
  return moduleData;
};
var generateSSOUrl = function generateSSOUrl(path) {
  var HOST = isProduction ? 'https://crehana.acsendo.com' : 'https://pruebas.acsendo.com';
  return "".concat(HOST, "/rest/saml3/login?next=").concat(HOST).concat(path);
};